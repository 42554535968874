import React from "react"
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig"

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "reset-password",
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/reset-password")
    ),
  },
]

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/dashboard")),
  },
  {
    key: "service",
    path: `${APP_PREFIX_PATH}/services`,
    component: React.lazy(() => import("views/app-views/services")),
  },
  {
    key: "service.add",
    path: `${APP_PREFIX_PATH}/services/add`,
    component: React.lazy(() =>
      import("views/app-views/services/serviceAppAdd")
    ),
  },
  {
    key: "service.edit",
    path: `${APP_PREFIX_PATH}/services/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/services/serviceAppEdit")
    ),
  },
  {
    key: "promotion",
    path: `${APP_PREFIX_PATH}/promotions`,
    component: React.lazy(() => import("views/app-views/promotions")),
  },
  {
    key: "promotion.add",
    path: `${APP_PREFIX_PATH}/promotions/add`,
    component: React.lazy(() =>
      import("views/app-views/promotions/promotionAppAdd")
    ),
  },
  {
    key: "promotion.edit",
    path: `${APP_PREFIX_PATH}/promotions/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/promotions/promotionAppEdit")
    ),
  },
  {
    key: "commercial",
    path: `${APP_PREFIX_PATH}/commercials`,
    component: React.lazy(() => import("views/app-views/commercials")),
  },
  {
    key: "commercial.add",
    path: `${APP_PREFIX_PATH}/commercials/add`,
    component: React.lazy(() =>
      import("views/app-views/commercials/commercialAppAdd")
    ),
  },
  {
    key: "commercial.edit",
    path: `${APP_PREFIX_PATH}/commercials/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/commercials/commercialAppEdit")
    ),
  },
  {
    key: "becomeAnAmbassador",
    path: `${APP_PREFIX_PATH}/ambassadors`,
    component: React.lazy(() => import("views/app-views/ambassadors")),
  },
  {
    key: "ambassador.add",
    path: `${APP_PREFIX_PATH}/ambassadors/add`,
    component: React.lazy(() =>
      import("views/app-views/ambassadors/ambassadorAppAdd")
    ),
  },
  {
    key: "ambassador.edit",
    path: `${APP_PREFIX_PATH}/ambassadors/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/ambassadors/ambassadorAppEdit")
    ),
  },
  {
    key: "ambassadorsRequest",
    path: `${APP_PREFIX_PATH}/ambassadorsRequest`,
    component: React.lazy(() => import("views/app-views/ambassadorsRequest")),
  },
  {
    key: "invitation",
    path: `${APP_PREFIX_PATH}/invitations`,
    component: React.lazy(() => import("views/app-views/invitations")),
  },
  {
    key: "relationship",
    path: `${APP_PREFIX_PATH}/relationships`,
    component: React.lazy(() => import("views/app-views/relationships")),
  },
  {
    key: "relationship.add",
    path: `${APP_PREFIX_PATH}/relationships/add`,
    component: React.lazy(() =>
      import("views/app-views/relationships/relationshipAppAdd")
    ),
  },
  {
    key: "relationship.edit",
    path: `${APP_PREFIX_PATH}/relationships/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/relationships/relationshipAppEdit")
    ),
  },
  {
    key: "clientCompany",
    path: `${APP_PREFIX_PATH}/clients`,
    component: React.lazy(() => import("views/app-views/clients")),
  },
  // {
  //   key: "message",
  //   path: `${APP_PREFIX_PATH}/messages`,
  //   component: React.lazy(() => import("views/app-views/message")),
  // },
  // {
  //   key: "message.list",
  //   path: `${APP_PREFIX_PATH}/messages/liste`,
  //   component: React.lazy(() => import("views/app-views/message/messageList")),
  // },
  // {
  //   key: "message.detail",
  //   path: `${APP_PREFIX_PATH}/messages/detail/:id`,
  //   component: React.lazy(() =>
  //     import("views/app-views/message/messageApplicationDetail")
  //   ),
  // },
  // {
  //   key: "message.compose",
  //   path: `${APP_PREFIX_PATH}/messages/compose`,
  //   component: React.lazy(() =>
  //     import("views/app-views/message/messageCompose")
  //   ),
  // },
  // {
  //   key: "Message.Inbox",
  //   path: `${APP_PREFIX_PATH}/messages/inbox`,
  //   component: React.lazy(() => import("views/app-views/message/messageInbox")),
  // },
  {
    key: "user",
    path: `${APP_PREFIX_PATH}/managers`,
    component: React.lazy(() => import("views/app-views/managers")),
  },
  {
    key: "user.add",
    path: `${APP_PREFIX_PATH}/managers/add`,
    component: React.lazy(() =>
      import("views/app-views/managers/managersAppAdd")
    ),
  },
  {
    key: "user.edit",
    path: `${APP_PREFIX_PATH}/managers/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/managers/managersAppEdit")
    ),
  },
  {
    key: "user.manageAccount",
    path: `${APP_PREFIX_PATH}/mon_compte`,
    component: React.lazy(() => import("views/app-views/account")),
  },
  {
    key: "history",
    path: `${APP_PREFIX_PATH}/histories`,
    component: React.lazy(() => import("views/app-views/histories")),
  },
]
