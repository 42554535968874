import fetch from "auth/FetchInterceptor"
import { AUTH_TOKEN } from "constants/AuthConstant"
const PromotionService = {}
PromotionService.getPromotions = function (page = 1) {
  return fetch({
    method: "get",
    url: `api/promotion/getAll?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
PromotionService.getPromotionsByCompany = function (page = 1) {
  return fetch({
    method: "get",
    url: `/api/promotion/getAllPromotionsForCompany?page=${page}&limit=5`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
PromotionService.createPromotion = function (data) {
  return fetch({
    method: "post",
    url: `api/promotion/create`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
PromotionService.getPromotion = function (id) {
  return fetch({
    method: "get",
    url: `api/promotion/getById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
PromotionService.deletePromotion = function (id) {
  return fetch({
    method: "delete",
    url: `api/promotion/deleteById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
PromotionService.updatePromotion = function (id, data) {
  return fetch({
    method: "put",
    url: `api/promotion/update/${id}`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
PromotionService.deletePromotions = function () {
  return fetch({
    method: "delete",
    url: `api/promotion/deleteAll`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
export default PromotionService
