import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import ServiceService from "services/ServiceService"

export const getAllServices = createAsyncThunk(
  "services/getAllServices",
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await ServiceService.getServices(page)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)
export const getAllServicesByCompany = createAsyncThunk(
  "services/getAllServicesByCompany",
  async (page = 1, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setCurrentPage(page))
      const response = await ServiceService.getServicesByCompany(page)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)
export const createService = createAsyncThunk(
  "service/createService",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ServiceService.createService(data)
      return response
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const updateService = createAsyncThunk(
  "services/updateService",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ServiceService.updateService(id, data)
      return { id, updatedService: response.data }
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const deleteService = createAsyncThunk(
  "services/deleteService",
  async (id, { rejectWithValue }) => {
    try {
      await ServiceService.deleteService(id)
      return id
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const fetchOneService = createAsyncThunk(
  "services/fetchOneService",
  async (id, { rejectWithValue }) => {
    try {
      console.log(id)
      const response = await ServiceService.getService(id)
      const serviceModified = {
        ...response.data.service,
        photo: [response.data.service.photo],
      }
      return serviceModified
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)
const initialState = {
  services: [],
  service: {},
  loading: false,
  error: null,
  currentPage: 1,
}

const serviceSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true
    },
    hideLoading: (state, action) => {
      state.loading = false
    },
    hideError: (state, action) => {
      state.error = null
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch services
      .addCase(getAllServices.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getAllServices.fulfilled, (state, action) => {
        state.loading = false
        state.services = action.payload.data
        state.totalItems = action.payload.totalItems
      })
      .addCase(getAllServices.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      }) // fetch services by company
      .addCase(getAllServicesByCompany.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getAllServicesByCompany.fulfilled, (state, action) => {
        state.loading = false
        state.services = action.payload.data
        state.totalItems = action.payload.totalItems
      })
      .addCase(getAllServicesByCompany.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      // Create Service
      .addCase(createService.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(createService.fulfilled, (state, action) => {
        state.services.push(action.payload)
        state.loading = false
        state.error = null
      })
      .addCase(createService.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      // Update service
      .addCase(updateService.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateService.fulfilled, (state, action) => {
        // const { id, updatedService } = action.payload
        // const index = state.services.findIndex((service) => service.id === id)
        // if (index !== -1) {
        //   state.services[index] = {
        //     ...state.services[index],
        //     ...updatedService,
        //   }
        // }
        state.loading = false
        state.error = null
      })
      .addCase(updateService.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      // Delete service
      .addCase(deleteService.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(deleteService.fulfilled, (state, action) => {
        // const serviceId = action.payload
        // state.services = state.services.filter(
        //   (service) => service._id !== serviceId
        // )
        state.loading = false
        state.error = null
      })
      .addCase(deleteService.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

      .addCase(fetchOneService.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchOneService.fulfilled, (state, action) => {
        state.loading = false
        // state.service = action.payload
        state.error = null
      })
      .addCase(fetchOneService.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  },
})
export default serviceSlice.reducer
export const { showLoading, hideLoading, hideError, setCurrentPage } =
  serviceSlice.actions
