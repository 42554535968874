import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import CommercialService from "services/CommercialService"

export const getAllCommercials = createAsyncThunk(
  "Commercials/getAllCommercials",
  async ({ companyId, page = 1 }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setCurrentPage(page))
      const response = await CommercialService.getCommercials(companyId, page)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)
export const deleteCommercial = createAsyncThunk(
  "Commercials/deleteCommercial",
  async ({ companyId, commercialId }, { rejectWithValue }) => {
    try {
      const response = await CommercialService.deleteCommercial(
        companyId,
        commercialId
      )
      return commercialId
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)
export const getCommercial = createAsyncThunk(
  "Commercials/getCommercial",
  async ({ companyId, commercialId }, { rejectWithValue }) => {
    try {
      const response = await CommercialService.getCommercial(
        companyId,
        commercialId
      )
      return response.data.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)
export const createCommercial = createAsyncThunk(
  "Commercials/createCommercial",
  async ({ companyId, data }, { rejectWithValue }) => {
    try {
      const response = await CommercialService.createCommercial(companyId, data)
      return response.data.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)
const initialState = {
  commercials: [],
  commercial: {},
  loading: false,
  error: null,
  currentPage: 1,
}

const commercialSlice = createSlice({
  name: "commercials",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true
    },
    hideLoading: (state, action) => {
      state.loading = false
    },
    hideError: (state, action) => {
      state.error = null
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch commercials
      .addCase(getAllCommercials.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getAllCommercials.fulfilled, (state, action) => {
        state.loading = false
        state.commercials = action.payload.data
        state.totalItems = action.payload.totalItems
      })
      .addCase(getAllCommercials.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      // Create Commercial
      .addCase(createCommercial.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(createCommercial.fulfilled, (state, action) => {
        state.commercials.push(action.payload)
        state.loading = false
        state.error = null
      })
      .addCase(createCommercial.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      // Update commercial
      // .addCase(updateCommercial.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(updateCommercial.fulfilled, (state, action) => {
      //   const { commercialId, updatedJob } = action.payload;
      //   const index = state.commercials.findIndex(
      //     (commercial) => commercial.id === commercialId
      //   );
      //   if (index !== -1) {
      //     state.commercials[index] = {
      //       ...state.commercials[index],
      //       ...updatedJob,
      //     };
      //   }
      //   state.loading = false;
      // })
      // .addCase(updateCommercial.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      // Delete commercial
      .addCase(deleteCommercial.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(deleteCommercial.fulfilled, (state, action) => {
        // const commercialId = action.payload
        // state.commercials = state.commercials.filter(
        //   (commercial) => commercial._id !== commercialId
        // )
        state.loading = false
        state.error = null
      })
      .addCase(deleteCommercial.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

      .addCase(getCommercial.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getCommercial.fulfilled, (state, action) => {
        state.loading = false
        state.commercial = action.payload
        state.error = null
      })
      .addCase(getCommercial.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  },
})
export default commercialSlice.reducer
export const { showLoading, hideLoading, hideError, setCurrentPage } =
  commercialSlice.actions
