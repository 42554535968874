import fetch from "auth/FetchInterceptor"
import { AUTH_TOKEN } from "constants/AuthConstant"
const ClientService = {}

ClientService.getClients = function (page = 1, idCompany) {
  return fetch({
    method: "get",
    url: `api/clientCompany/getAll/${idCompany}?page=${page}&limit=2`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
ClientService.searchClients = function (page, value, idCompany) {
  let url = `api/clientCompany/getAll/${idCompany}?page=${page}&fullName=${value}`
  if (!value) {
    url = `api/clientCompany/getAll/${idCompany}?page=${page}`
  }

  return fetch({
    method: "get",
    url: url + "&limit=2",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
ClientService.createClient = function (data) {
  return fetch({
    method: "post",
    url: `api/clientCompany/create`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

ClientService.deleteClient = function (id) {
  return fetch({
    method: "delete",
    url: `api/clientCompany/deleteById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
//n'existe pas pour l'instant
ClientService.updateClient = function (id, data) {
  return fetch({
    method: "put",
    url: `api/clientCompany/updateById/${id}`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

export default ClientService
