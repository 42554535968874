import fetch from "auth/FetchInterceptor"
import { AUTH_TOKEN } from "constants/AuthConstant"
const InvitationService = {}
InvitationService.getInvitations = function (
  page = 1,
  status = null,
  searchValue
) {
  let url = `api/invitation/getAll?page=${page}`

  if (status && status.length > 0 && searchValue) {
    url = `api/invitation/getAll?page=${page}&status=${status}&client=${searchValue}`
  } else if (status && status.length > 0) {
    url = `api/invitation/getAll?page=${page}&status=${status}`
  } else if (searchValue) {
    url = `api/invitation/getAll?page=${page}&client=${searchValue}`
  }
  return fetch({
    method: "get",
    url: url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
InvitationService.deleteInvitation = function (invitationId) {
  return fetch({
    method: "delete",
    url: `api/invitation/deleteById/${invitationId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
InvitationService.getInvitation = function (invitationId) {
  return fetch({
    method: "get",
    url: `api/invitation/getById/${invitationId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
InvitationService.sendInvitation = function (invitationId) {
  return fetch({
    method: "post",
    url: `api/invitation/send/${invitationId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

InvitationService.getPending = function () {
  return fetch({
    method: "get",
    url: `api/invitation/getPending`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

export default InvitationService
