import fetch from "auth/FetchInterceptor"
import { AUTH_TOKEN } from "constants/AuthConstant"
const DashboardService = {}

DashboardService.getNumberOfValidatedAmbassadors = async (companyId) =>
  await fetch({
    method: "get",
    url: `api/companies/${companyId}/validated-become-an-ambassador-requests`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })

DashboardService.getNumberOfRefusedAmbassadors = async (companyId) =>
  await fetch({
    method: "get",
    url: `api/companies/${companyId}/refused-become-an-ambassador-requests`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })

DashboardService.getNumberOfAquiredClients = async (companyId) =>
  await fetch({
    method: "get",
    url: `api/companies/${companyId}/clients-acquired`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })

DashboardService.getAllRelations = async (companyId) =>
  await fetch({
    method: "get",
    url: `api/companies/${companyId}/all-relations`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })

DashboardService.getNumberOfCommercials = async (companyId) =>
  await fetch({
    method: "get",
    url: `api/companies/${companyId}/commercials-count`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })

DashboardService.getRelationsSuccessByMonth = async ({ companyId, year }) =>
  await fetch({
    method: "get",
    url: `api/companies/${companyId}/succeeded-relations-ratio-per-month/${year}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })

DashboardService.getTopServices = async (companyId) =>
  await fetch({
    method: "get",
    url: `api/companies/${companyId}/top-services`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })

DashboardService.getImage = async (companyId) =>
  await fetch({
    method: "get",
    url: `api/companies/${companyId}/image`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })

DashboardService.becomeVIP = async () =>
  await fetch({
    method: "post",
    url: `api/becomeVIP/create`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
export default DashboardService
