import fetch from "auth/FetchInterceptor"
import { AUTH_TOKEN } from "constants/AuthConstant"
const CommercialService = {}
CommercialService.getCommercials = function (companyId, page = 1) {
  return fetch({
    method: "get",
    url: `api/commercial/company/${companyId}/getCommercialsForCompany?page=${page}&limit=5`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
CommercialService.deleteCommercial = function (companyId, commercialId) {
  return fetch({
    method: "delete",
    url: `api/commercial/company/${companyId}/deleteCommercialById/${commercialId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
CommercialService.getCommercial = function (companyId, commercialId) {
  return fetch({
    method: "get",
    url: `api/commercial/company/${companyId}/getCommercialById/${commercialId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
CommercialService.createCommercial = function (companyId, data) {
  return fetch({
    method: "post",
    url: `api/commercial/company/${companyId}/createCommercial`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
      "Content-Type": "application/json",
    },
  })
}
export default CommercialService
